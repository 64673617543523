import Button, { ButtonProps } from '~/components/interactive/Button';
import { NavLink } from '@remix-run/react';
import LoadingButton from '~/components/interactive/LoadingButton';
import classNames from 'classnames';

export type NavigationButtonProps = Omit<ButtonProps, 'onClick' | 'type'> & { to: string; popup?: boolean };

export default function NavigationButton({ to, popup, ...props }: NavigationButtonProps) {
  return (
    <NavLink
      to={to}
      className={classNames({
        'cursor-not-allowed': props.disabled,
      })}
      onClick={(e) => {
        if (props.disabled) {
          e.preventDefault();

          return;
        }

        if ((to.includes('http') && popup !== false) || popup) {
          window.open(to, '_blank');

          e.preventDefault();
        }

        if (props.disabled) {
          e.preventDefault();
        }
      }}
    >
      {popup ? <Button {...props} type="button" /> : <LoadingButton {...props} type="button" />}
    </NavLink>
  );
}
